<template>
  <a-modal
    title="编辑"
    :width="780"
    :visible="visible"
    :confirmLoading="confirmLoading"
    :destroyOnClose="true"
    @ok="handleSubmit"
    @cancel="handleCancel"
  >
    <a-spin :spinning="confirmLoading">
      <a-row :gutter="24">
        <a-col :md="24" :sm="24">
          <a-form :form="form">
            <!-- 查询商家搜索框 -->
            <a-form-item label="商家" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
              <a-select
                show-search
                v-decorator="['storeId', { rules: [{ required: true, message: '请搜索商家昵称或手机号！' }] }]"
                placeholder="请搜索商家昵称、ID号、手机号"
                :default-active-first-option="false"
                :show-arrow="false"
                :filter-option="false"
                :not-found-content="null"
                @search="handleSearch"
                disabled
              >
                <a-select-option
                  v-for="item in searchList"
                  :key="item.id"
                  :value="item.id"
                >{{item.name}}</a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="推荐时间" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
              <a-range-picker allow-clear v-model="timeRange" show-time style="max-width: 560px" format="YYYY-MM-DD hh:mm"/>
            </a-form-item>
            <!-- 排序 -->
            <a-form-item label="排序" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
              <a-input-number 
                placeholder="请输入"
                :min="1"
                :max="10000"
                style="width: 40%;min-width: 100px"
                v-decorator="['sort', { rules: [{ required: true, message: '请输入！' }] }]"
              />
            </a-form-item>
          </a-form>
        </a-col>
      </a-row>
    </a-spin>
  </a-modal>
</template>

<script>
import { recommendStoreDetail, recommendStoreEdit } from '@/api/modular/mallLiving/recommendStore'
import { storeList } from '@/api/modular/mallLiving/message'
import moment from 'moment'
export default {
  data() {
    return {
      loading: false,
      labelCol: {
        xs: { span: 24 },
        sm: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
      visible: false,
      confirmLoading: false,
      form: this.$form.createForm(this),
      pictureLoading:false,
      picture:'',
      searchList:[],
      timeRange: []
    }
  },

  methods: {
    // 初始化方法
    edit(record){
        this.visible = true
        this.id = record.id
        this.confirmLoading = true
        //获取详情数据
        setTimeout(()=>{
            recommendStoreDetail({id: record.id}).then((res)=>{
                if(res.success){
                    let details = res.data
                    // 调搜索商家接口
                    this.handleSearch(details.storeName)
                    setTimeout(()=>{
                      this.form.setFieldsValue({
                        //storeId通过上面接口获取的searchList,显示对应的昵称
                        storeId: details.storeId,  
                        sort: details.sort
                      })
                    },200)
                    this.timeRange = []
                    this.timeRange.push(moment(details.beginTime))
                    this.timeRange.push(moment(details.endTime))
                    this.confirmLoading = false
                }
            }).finally((res)=>{
                setTimeout(()=>{
                    this.confirmLoading = false
                },5000)
            })
        },150)
        
    },
    onChange(date) {
			var date = new Date(date).getTime()
			return date
		},
    handleSubmit() {
      this.confirmLoading = true
      this.form.validateFields((errors, values) => {
        if (!errors) {
          if(!this.timeRange){
            this.$message.error("请选择推荐时间")
            this.confirmLoading = false
            return
          }else{
            values.beginTime = this.onChange(this.timeRange[0]._d)
            values.endTime = this.onChange(this.timeRange[1]._d)
            recommendStoreEdit({id: this.id, ...values}).then((res) => {
              if(res.success){
                this.$message.success("编辑成功！")
                this.$emit("ok",values)
                this.confirmLoading = false
                this.handleCancel()
              }else{
                setTimeout(()=>{
                  this.confirmLoading = false
                }, 600)
              }
            })
          }
            
        } else {
          this.confirmLoading = false
        }
      })
    },
    // 搜索框搜索商家
    handleSearch(value){
      storeList({keywords: value}).then((res)=>{
        if(res.success){
          this.searchList = res.data.map((item)=>{
            return {
              id:item.id,
              name:item.nickname
            }
          })
        }
      })
    },
    handleCancel() {
      this.visible = false //关闭对话框
      this.confirmLoading = false
      this.searchList = []
      this.form.resetFields() //重置表单
    }
  },
}
</script>
<style scoped>
.flex {
  display: flex;
}
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
</style>